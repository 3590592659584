<template>
  <div>
  </div>
</template>

<script>
import { useAuth } from '../../state/auth';
export default {
  data() {
    return {
      authState: useAuth(),
      columnDefs: null,
      rowData: null,
    };
  },
  components: {
    //    AgGridVue,
  },
  beforeMount() {
    this.authState.isUserActive();

    this.authState.setLayout(true)
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped></style>