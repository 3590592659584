<template>
    <div>

        <table border=0 cellpadding=0 cellspacing=0 style='border-collapse:
 collapse;table-layout:fixed;'>
 <col width=69 style='mso-width-source:userset;mso-width-alt:2523;width:52pt'>
 <col width=78 style='mso-width-source:userset;mso-width-alt:2852;width:59pt'>
 <col width=79 style='mso-width-source:userset;mso-width-alt:2889;width:59pt'>
 <col width=64 span=6 style='width:48pt'>
 <tr height=21 style='mso-height-source:userset;height:15.75pt'>
  <td colspan=9 height=21 class=xl733477 width=610 style='border-right:1.0pt solid black;
  height:15.75pt;width:458pt'>{{ TankNo }}</td>
 </tr>
 <tr height=21 style='height:15.75pt' v-if="isholdingtank">
  <td height=21 class=xl663477 style='height:15.75pt;border-top:none'>Total WIP</td>
  <td colspan=8 class=xl663477 style='border-left:none'>{{totalwip}}</td>
 </tr>
 <tr height=21 style='height:15.75pt' v-else>
  <td height=21 class=xl663477 style='height:15.75pt;border-top:none'>Status</td>
  <td colspan=8 class=xl663477 style='border-left:none'>{{status}}</td>
 </tr>
 <tr height=21 style='height:15.75pt'>
  <td colspan=9 height=21 class=xl703477 style='border-right:1.0pt solid black;
  height:15.75pt'>Downtime</td>
 </tr>
 <tr height=21 style='height:15.75pt'  v-for="(downtimeitem,index) in downtime" :key="index" >
  <td height=21 class=xl653477 style='height:15.75pt;border-top:none'>{{index+1}}</td>
  <td colspan=8 class=xl673477 style='border-right:1.0pt solid black;
  border-left:none'>{{ downtimeitem }}</td>
 </tr>

 <tr height=21 style='height:15.75pt'>
  <td colspan=9 height=21 class=xl703477 style='border-right:1.0pt solid black;
  height:15.75pt'>Issue</td>
 </tr>
 <tr height=21 style='height:15.75pt'  v-for="(issueitem,index) in issue" :key="index" >
  <td height=21 class=xl653477 style='height:15.75pt;border-top:none'>{{index+1}}</td>
  <td colspan=8 class=xl673477 style='border-right:1.0pt solid black;
  border-left:none'>{{ issueitem }}</td>
 </tr>

 <tr height=0 style='display:none'>
  <td width=69 style='width:52pt'></td>
  <td width=78 style='width:59pt'></td>
  <td width=79 style='width:59pt'></td>
  <td width=64 style='width:48pt'></td>
  <td width=64 style='width:48pt'></td>
  <td width=64 style='width:48pt'></td>
  <td width=64 style='width:48pt'></td>
  <td width=64 style='width:48pt'></td>
  <td width=64 style='width:48pt'></td>
 </tr>

</table>

    </div>
</template>

<script>
    export default {
        props : ["TankNo","totalwip", "status","downtime", "issue", "isholdingtank"]
        ,
        methods: {
            refresh(){
                this.$forceUpdate();
            }
        }
        }
</script>

<style id="Book1_3477_Styles">

.xl153477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:general;
	vertical-align:bottom;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl653477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:general;
	vertical-align:bottom;
	border:1.0pt solid ;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl663477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border:1.0pt solid ;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl673477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:1.0pt solid ;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl683477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:none;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl693477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:1.0pt solid ;
	border-bottom:1.0pt solid ;
	border-left:none;
	mso-background-source:auto;
	mso-pattern:auto;
	white-space:nowrap;}
.xl703477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:1.0pt solid ;
	background:#FFC000;
	mso-pattern:black none;
	white-space:nowrap;}
.xl713477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:none;
	background:#FFC000;
	mso-pattern:black none;
	white-space:nowrap;}
.xl723477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:1.0pt solid ;
	border-bottom:1.0pt solid ;
	border-left:none;
	background:#FFC000;
	mso-pattern:black none;
	white-space:nowrap;}
.xl733477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:1.0pt solid ;
	background:#00B0F0;
	mso-pattern:black none;
	white-space:nowrap;}
.xl743477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:none;
	border-bottom:1.0pt solid ;
	border-left:none;
	background:#00B0F0;
	mso-pattern:black none;
	white-space:nowrap;}
.xl753477
	{padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:black;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:Calibri, sans-serif;
	mso-font-charset:0;
	mso-number-format:General;
	text-align:center;
	vertical-align:bottom;
	border-top:1.0pt solid ;
	border-right:1.0pt solid ;
	border-bottom:1.0pt solid ;
	border-left:none;
	background:#00B0F0;
	mso-pattern:black none;
	white-space:nowrap;}

</style>