<template>
      <div class="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">

<!-- auth-page content -->
<div class="auth-page-content overflow-hidden p-0">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-4 text-center">
                <div class="error-500 position-relative">
                   
                    <h1 class="title text-muted">Error</h1>
                </div>
                <div>
                    <h4>Make sure you're entered valid password or any credential</h4> <br>
  <h4>Please try again and use the valid !</h4> 
                    
                    <p class="text-muted w-75 mx-auto">You will redirected in 5 seconds!</p>
                   
                </div>
            </div><!-- end col-->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end auth-page content -->
</div>
</template>

<script>
    export default {
        
        mounted() {
            setTimeout(() => window.location = '/login',4000);
        },
    }
</script>

<style lang="scss" scoped>

</style>