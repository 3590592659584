<template>
    <div>
        <button @click="exportData">Download file</button>  <button class="btn btn-success" @click="sheetx">x file</button>
    </div>
</template>

<script>
import { excelParser } from "./exportjson";
import * as XLSX from 'xlsx';
// const { utils } = XLSX;

export default {
    data() {
        return {
            nestedData : [
  {
    groupName: "Group A",
    commonColumn: "Value 1",
    col1: 10,
    col2: "Info A",
    details: [
      { subCol1: "Detail 1A", subCol2: 50 },
      { subCol1: "Detail 2A", subCol2: 60 },
    ],
  },
  // ... (more data)
],
            data: [
                { id: 1, name: "Night", email: "nightprogrammer95@gmail.com",
            detail :[{

                alamat : 'jl xxxx',
                kecamatan : 'xdps'
            }]
            },
                { id: 2, name: "Gautam", email: "mailgautam@test.com" },
                { id: 3, name: "Alex", email: "xalex@testmail.com" },
                { id: 4, name: "Lina", email: "lina@mail.com" },
                { id: 5, name: "Peter", email: "peter.me@test.com" }
            ]
        }
    },
    methods: {
        exportData() {
            excelParser().exportDataFromJSON(this.data, null, null);
        },
        sheetx(){
        // Initialize an array to hold the flattened data
const flattenedData = [];

// Flatten the nested data
this.nestedData.forEach((item) => {
  const flattenedItem = {
    "Group Name": item.groupName,
    "Common Column": item.commonColumn,
    "Col1": item.col1,
    "Col2": item.col2,
  };

  flattenedData.push(flattenedItem);

  // Check if the item has details
  if (item.details && item.details.length > 0) {
    item.details.forEach((detail) => {
      const flattenedDetail = {
        "Group Name": "", // Leave this empty for subgrid rows
        "Common Column": "", // Leave this empty for subgrid rows
        "Col1": detail.subCol1,
        "Col2": detail.subCol2,
      };
      flattenedData.push(flattenedDetail);
    });
  }
});

// Create a new workbook and add the data
const wb = XLSX.utils.book_new();
const ws = XLSX.utils.json_to_sheet(flattenedData);
XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

// Export the workbook to a file (e.g., Excel format)
XLSX.writeFile(wb, "nestedData.xlsx");
        }
    },
}
</script>

<style lang="scss" scoped></style>