import { defineStore } from 'pinia';
import {supabase} from '../supabase/supabase.js'

export const useAuth = defineStore({
  id: 'auth',
  state: () => ({
    isLayout: null,
    token: "",
    user:null,
    userdetail:null
  }),
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions: {
    increment() {
      this.count++;
    },
    setLayout(val){
        this.isLayout = val
    },
    setToken(val){
        this.token = val 
    },
    setUserDetail(val){
            this.userdetail = val
    },
    async logout(){
      this.isLayout= null
      this.token= ""
      this.user=null
      this.userdetail=null

      const { error } = await supabase.auth.signOut()
      console.log(error)
      window.location.href = '/login'
    },
   async isUserActive(){


        const { data } = await supabase.auth.getSession();
     if(data.session==null){
      window.location.href = '/login'
     }
  
    },
    async getusername(nik){
      const { data, error } = await supabase.rpc('get_user_name', {'nik_param':nik})
      console.log(error)
      console.log(data, 'datax')
      return data
  },
setUser(val){
    this.user = val;
}
},
persist: {
    enabled: true
  }
});