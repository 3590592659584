<template>
    <!-- auth-page wrapper -->
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <!-- auth-page content -->
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card overflow-hidden">
                            <div class="row g-0">
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <a href="#" class="d-block">
                                                    <img src="assets/logo.png" alt="" height="40">
                                                </a>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div id="qoutescarouselIndicators" class="carousel slide"
                                                    data-bs-ride="carousel">
                                                    <div class="carousel-indicators">
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="0" class="active" aria-current="true"
                                                            aria-label="Slide 1"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    </div>
                                         
                                                    <div class="carousel-inner text-center text-white pb-5">
                                                        <div class="carousel-item active">
                                                            <p class="fs-15 fst-italic">"Automation empowers workers for unprecedented efficiency and innovation."</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">"Sets a new standard of precision and speed in every sector"</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">"Elevates craftsmanship with unmatched precision, reshaping industries for limitless possibility "</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end carousel -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->

                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                            <h5 class="text-primary">Welcome !</h5>
                                            <p class="text-muted">Sign in to KeyIn System.</p>
                                        </div>

                                        <div class="mt-4">

                                           
                                            <FormulateForm v-model="formValues" @submit="submitHandler">




                                                <div class="mb-3">
                                                    <FormulateInput validation="required" type="email" name="email"
                                                        label-class="form-label" input-class="form-control" label="Email" />

                                                </div>

                                                <div class="mb-3">
                                                    <!-- <div class="float-end">
                                                        <a href="/forgotpassword" class="text-muted">Forgot
                                                            password?</a>
                                                    </div> -->

                                                    <label class="form-label" for="password-input">Password</label>
                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <FormulateInput id="password-input" validation="required"
                                                            type="password" name="password" label-class="form-label"
                                                            input-class="form-control pe-5 password-input" />

                                                        <!-- <input type="password" class="form-control pe-5 password-input"
                                                            placeholder="Enter password" > -->

                                                    </div>
                                                </div>






                                                <div class="mt-4">
                                                    <FormulateInput type="submit" label-class="form-label"
                                                        input-class="btn btn-success w-100" label="Sign In" />
                                                    <!-- 
                                                    <button class="btn btn-succes w-100" type="submit">Sign In</button> -->
                                                </div>  
                                                <div class="mt-4">
                                                    <router-link  label-class="form-label" to="/forgotpassword"
                                                        class="btn btn-warning w-100" label="Forgot Password">Forgot Password</router-link>
                                                    <!-- 
                                                    <button class="btn btn-succes w-100" type="submit">Sign In</button> -->
                                                </div>
                                            </FormulateForm>

                                        </div>

                                        <div class="mt-5 text-center">
                                            <p class="mb-0">Don't have an account ? <a href="/register"
                                                    class="fw-semibold text-primary text-decoration-underline"> Signup</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!-- end row -->
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->

                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0">&copy;

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->

    </div>
    <!-- end auth-page-wrapper -->
</template>

<script>
//import Vue from 'vue'
import { useAuth } from '../../state/auth';
import { supabase } from '../../supabase/supabase';

export default {
    data() {
        return {
            authState: useAuth(),
            formValues: {},
            value1: null,
            showAlert: false,
            alertcontent: '',
            alertcolor: ''
        }
    },
    beforeCreate() {
    
    },created() {
      this.authState.setLayout(false)
    var settings = {
      canTimeout: true,
      defaultTitle: true,
      duration: 5000,
      withBackdrop: true,
      position: "top-right",
    };
    this.$vToastify.setSettings(settings);
  },
    methods: {
   
        async submitHandler() {
            try {
                const { data:data1, error:error1 } = await supabase.auth.signInWithPassword({
                    email: this.formValues.email,
                    password: this.formValues.password,
                })

                if (error1) {
                    // Handle the error here
                     this.$vToastify.error(
                    error1.message,
            "Error")
                    console.error(error1);
                } else {
                    // Handle the successful signup here
                   console.log('Signin success:', data1);
  var userid = data1.user.id
                   console.log(userid)
                  this.authState.setUser(data1);
                 //   Now that you have the user's ID, you can use it to fetch custom data
                 try {
                    let { data : data2, error:error2 } = await supabase
    .from('UserDetail')
     .select("*")
    .eq('userid', userid);
    console.log(data2)
                  this.authState.setUserDetail(data2)
              window.location.href = '/'
                 console.log(error2)
 
                 } catch (e2) {
                        console.log(e2)                    
                 }

                              }

            } catch (error) {
        //         this.$vToastify.error(
        //             error.message,
        //     "Error"
        //   );
                console.log(error.message)
            }

        }
    }
}
</script>

<style lang="scss" scoped></style>