import Vue from 'vue'
import App from './App.vue'
import routes from './route/routes'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import { registerLicense } from '@syncfusion/ej2-base';
import VueToastify from "vue-toastify";
registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adkdiWH9fcXVST2dV');
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueFormulate from '@braid/vue-formulate'
import piniaPersist from 'pinia-plugin-persist'
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(VueFormulate)
Vue.use(PiniaVuePlugin)
Vue.use(VueToastify)
const pinia = createPinia()
pinia.use(piniaPersist)
Vue.config.productionTip = false
Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

new Vue({
  render: h => h(App),
  vuetify,
  router,
  pinia
}).$mount('#app')
