<template>
    <div>
            Please wait..
    </div>
</template>

<script>
import { useAuth } from '../../state/auth';
export default {
        data() {
            return {
                authState: useAuth(),
            }
        },
        mounted() {
            this.authState.logout();
        },
    }
</script>

<style lang="scss" scoped>

</style>