<template>
    <div>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Form Holding Tank</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="javascript: void(0);">Key In</a></li>
                            <li class="breadcrumb-item active">Holding Tank</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title mb-0">Holding Tank</h4>
                    </div>
                    <div class="card-body">
                        <vue-confirm-dialog></vue-confirm-dialog>
                        <FormulateForm ref="formx" v-model="formValues" >

                            <div class="row g-3">


                                <div class="col-lg-6">
                                    <div>
                                        Date
                                        <input type="date" v-model="formValues.date" class="form-control">
                                    
                                        <br>
                                    
                                        <div class="input-group">
                                            <label class="input-group-text">Shift</label>
                                            <select class="form-select" v-model="formValues.shift">
                                                <option selected="">Choose...</option>
                                                <option value="1">Shift 1</option>
                                                <option value="2">Shift 2</option>
                                                <option value="3">Shift 3</option>
                                            </select>
                                        </div>
                                        <br>
                                        <div class="input-group">
                                            <label class="input-group-text">Tank No</label>
                                            <select class="form-select" v-model="formValues.TankNo">
                                                <option selected="">Choose...</option>
                                                <option value="T81/82/83">T81/82/83</option>
                                                <option value="T91/92/93">T91/92/93</option>
                                                <option value="T161/162/163">T161/162/163</option>
                                            </select>
                                        </div>
                                        <br>
                                        
                                    Total WIP
                                        <input type="text" v-model="TotalWIPformatted" placeholder="Total WIP" class="form-control">
                                    <br>
                                        <div class="input-group">
                                            <input type="text" v-model="downtimetext" class="form-control"
                                                placeholder="Input the downtime" aria-label="Recipient's username"
                                                aria-describedby="basic-addon2">
                                            <button class="input-group-text btn btn-warning" @click="adddowntime">Add
                                                downtime</button>
                                        </div>
                                        <br>
                                        <div class="live-preview">
                                            <ul class="list-group">
                                                <li v-for="(item, index) in downtime" :key="item"
                                                    class="list-group-item d-flex justify-content-between align-items-center">
                                                    {{ item.Downtime }} <span class="btn btn-danger"
                                                        @click="deletedowntimeitem(index)">Delete</span></li>

                                            </ul>
                                        </div>
                                        <br>

                                        <div class="input-group">
                                            <input type="text" v-model="issuestext" class="form-control"
                                                placeholder="Input the issues" aria-label="Recipient's username"
                                                aria-describedby="basic-addon2">
                                            <button class="input-group-text btn btn-warning" @click="addissues">Add
                                                issues</button>
                                        </div>
                                        <br>
                                        <div class="live-preview">
                                            <ul class="list-group">
                                                <li v-for="(item, index) in issues" :key="item"
                                                    class="list-group-item d-flex justify-content-between align-items-center">
                                                    {{ item.Issues }} <span class="btn btn-danger"
                                                        @click="deleteissuesitem(index)">Delete</span></li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div class="mt-3">

                                    <button class="btn btn-success" @click="saveData">Save</button>
                                </div>

                            </div>



                        </FormulateForm>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
    </div>
</template>

<script>

import { useAuth } from '../../state/auth'
import { supabase } from '../../supabase/supabase'
import * as moment from 'moment'
import Vue from 'vue'
import VueNumeralFilterInstaller from 'vue-numeral-filter'

Vue.use(VueNumeralFilterInstaller)
export default {
    data() {
        return {
            authState: useAuth(),
            xdate: null,
            downtimetext: '',
            issuestext: '',
            TotalWIPformatted : '',
            formValues: {
                    TotalWIP: null,
                    nik : ''
            },
            modelform: [
                {
                    date: '',
                    shift: '',
                  
                    output: '',
                    plan: '',
                    achievement: '',
                    line: '',
                    MixingNo: "1",
                  
                },
                {
                    date: '',
                    shift: '',
                   
                    output: '',
                    plan: '',
                    achievement: '',
                    line: '',
                    MixingNo: "2",
                    id_downtime: '',
                    id_issue: '',
                },
                {
                    date: '',
                    shift: '',

                    output: '',
                    plan: '',
                    achievement: '',
                    line: '',
                    MixingNo: "3",
                    id_downtime: '',
                    id_issue: '',
                }
            ],
            downtime: [

            ],
            issues: []
        }
    },

    beforeMount() {
        this.authState.isUserActive();

        this.authState.setLayout(true)
    },
    mounted(){

        this.formValues.nik =  this.authState.userdetail[0].nik
},

    watch: {
        'TotalWIPformatted':function(newValue) {
      // Remove non-numeric characters and add comma as thousands separator
     const result =    newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      Vue.nextTick(() => this.TotalWIPformatted = result);
     
      this.formValues.TotalWIP = result.replace(/\./g, '');
 
    },
        formValues(val) {

            this.modelform.forEach(element => {
                element.shift = val.shift
                element.date = val.date,
                element.line = val.line
            });
        },

    },
   computed:{
    
   },
    created() {

        this.$root.$refs.MAccount = this;
        var settings = {
            canTimeout: true,
            defaultTitle: true,
            duration: 5000,
            withBackdrop: true,
            position: "top-right",
        };
        this.$vToastify.setSettings(settings);
    },
    methods: {

        formatDate(dateString) {
            const parts = dateString.split('/');
            const day = parts[0].padStart(2, '0');
            const month = parts[1].padStart(2, '0');
            const year = parts[2];

            return `${year}-${month}-${day} 00:00:00`;
        },
        async saveData() {
            try {
               
                this.$confirm(
                    {
                        message: 'Are you sure? \n The data at this day and at this shift will be replaced!',
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                        * Callback Function
                        * @param {Boolean} confirm
                        */
                        callback: async confirm => {
                            if (confirm) {
                                try {
                                    const thedate = moment(this.formValues.date).format("YYYY-MM-DD")
                                    console.log(thedate)
                                    const { data: rpc, error: delerror } = await supabase.from('HoldingTank').delete()
                                        .eq('shift', this.formValues.shift)
                                        .eq('date', thedate)
                                        .eq('TankNo', this.formValues.TankNo)
                                        
                                    console.log(rpc)
                                    console.log(delerror)
                                } catch (errord) {
                                    console.log(errord)
                                }

 try {
                                    const thedate = moment(this.formValues.date).format("YYYY-MM-DD")
                                   
                                    const { data: rpc, error: delerror } = await supabase.from('HoldingTankDowntime').delete()
                                        .eq('shift', this.formValues.shift)
                                        .eq('date', thedate)
                                        .eq('TankNo', this.formValues.TankNo)
                                    
                                    console.log(rpc)
                                    console.log(delerror)
                                } catch (errord) {
                                    console.log(errord)
                                }

                                try {
                    const thedate = moment(this.formValues.date).format("YYYY-MM-DD")
                                    console.log(thedate)
                                    const { data: rpc, error: delerror } = await supabase.from('HoldingTankIssue').delete()
                                        .eq('shift', this.formValues.shift)
                                        .eq('date', thedate)
                                        .eq('TankNo', this.formValues.TankNo)
                                    
                                    console.log(rpc)
                                    console.log(delerror)
                } catch (error) {
                    
                    console.log(error)
                }




                                 
                             
                                    const { data: res, error: insertError } = await supabase
                                        .from('HoldingTank')
                                        .upsert(this.formValues)
                                        .select()
                                    console.log(res)
                                    console.log(insertError)
                                 if(insertError){
                                    this.$vToastify.error(
                                        insertError.message,
                                        "Error")
                             
                                 }
                                 else{
                                    
                                    const { data: downtimemix, error: downtimemixError } = await supabase
                                        .from('HoldingTankDowntime')
                                        .upsert(this.downtime)
                                        .select()
                                    console.log(downtimemix)
                                    console.log(downtimemixError)

                                        
                                    const { data: issuemix, error: issuemixError } = await supabase
                                        .from('HoldingTankIssue')
                                        .upsert(this.issues)
                                        .select()
                                    console.log(issuemix)
                                    console.log(issuemixError)
                                    this.$vToastify.success(
                                        "The data for this date " + this.formValues.date + " has been successfully saved!",
                                        "Success")
                             

                                 }
                                // if(error){

                                // }

                            }
                        }
                    }
                )



            } catch (er) {
                console.log(er)
            }
        },
        adddowntime() {
            if (this.downtimetext != '' && this.formValues.shift !=undefined && this.formValues.date !=undefined) {
                this.downtime.push({
                    date: this.formValues.date,
                    shift: this.formValues.shift,
                    TankNo : this.formValues.TankNo,
                    Downtime: this.downtimetext

                })
                this.downtimetext = ''
            }
            else {

                this.$vToastify.error(
                    "Please fill the downtime Title first. Make sure the shift and date is selected!",
                    "Error")

            }

        },
        addissues() {
            if (this.issuestext != ''&& this.formValues.shift !=undefined && this.formValues.date !=undefined) {
                this.issues.push({
                    date: this.formValues.date,
                    shift: this.formValues.shift,
                    TankNo : this.formValues.TankNo,
                    Issues: this.issuestext

                })
                this.issuestext = ''
            }
            else {

                this.$vToastify.error(
                    "Please fill the issues Title first. Make sure the shift and date is selected!",
                    "Error")
7
            }

        },
        deletedowntimeitem(index) {
            this.downtime.splice(index, 1)
        },
        deleteissuesitem(index) {
            this.issues.splice(index, 1)
        },
      async  postlist(){
          
        }
    }

}
</script>

<style lang="css" scoped></style>