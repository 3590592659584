<template>
	<div>

		<router-link to="/" class="btn btn-primary  hidden-print"> <i class="mdi mdi-message-arrow-left"
				style="font-size:15px"></i> Back to Main Page</router-link>
		<br>
		<br>
		<div class="columns is-multiline hidden-print">
			<div class="column is-3">

				<input type="date" v-model="date" class="form-control">
			</div>
			<div class="column is-3">
				<select required class="form-control" v-model="shift">
					<option value="">Select Shift</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
				</select>
			</div>
			<div class="column is-3">

				<button class="btn btn-success" @click="generate">OK</button>
			</div>
		</div>


		<br>
		<br>
		<div class="row justify-content-center" id="printarea">
			<div class="col-xxl-9">
				<div class="card" id="demo">

					<div class="row">
						<div class="col-lg-12">
							<div class="card-header border-bottom-dashed p-4">
								<div class="d-flex">
									<div class="flex-grow-1">
										<img src="assets/logo.png" class="card-logo card-logo-dark" alt="logo dark"
											height="50">
										<img src="assets/logo.png" class="card-logo card-logo-light" alt="logo light"
											height="50">
										<div class="mt-sm-5 mt-4">
											<h6 class="text-muted text-uppercase fw-semibold">Report Shiftly</h6>
											<p class="text-muted mb-1" id="address-details">Area Liquid</p>
											<p class="text-muted mb-0" id="zip-code"><span>Code</span> unknown </p>
										</div>
									</div>
									<div data-v-4311a218="" class="flex-shrink-0 mt-sm-0 mt-3">
										<h6 data-v-4311a218="" class="text-muted text-uppercase fw-semibold mb-3">Indolakto
										</h6>
										<p data-v-4311a218="" id="billing-name" class="fw-medium mb-2">Purwosari</p>
										<p data-v-4311a218="" id="billing-address-line-1" class="text-muted mb-1"></p>
										<p data-v-4311a218="" class="text-muted mb-1"><span data-v-4311a218="">Jl. Raya
												Purwosari No.Km. 62</span><span data-v-4311a218=""
												id="billing-phone-no"></span></p>
										<p data-v-4311a218="" class="text-muted mb-0"><span data-v-4311a218="">Telephone
												:</span><span data-v-4311a218="" id="billing-tax-no">(0343) 611466</span>
										</p>
									</div>
								</div>
							</div>
							<!--end card-header-->
						</div><!--end col-->
						<div class="col-lg-12">
							<div class="card-body p-4">
								<div class="row g-3">
									<div class="col-lg-3 col-6">
										<p class="text-muted mb-2 text-uppercase fw-semibold">Item No</p>
										<h5 class="fs-14 mb-0">545dsfer21242d332434</h5>
									</div>
									<!--end col-->
									<div class="col-lg-3 col-6">
										<p class="text-muted mb-2 text-uppercase fw-semibold">Date</p>
										<h5 class="fs-14 mb-0"><span id="invoice-date">{{ date }}</span> <small
												class="text-muted" id="invoice-time"></small></h5>
									</div>
									<!--end col-->
									<div class="col-lg-3 col-6">
										<p class="text-muted mb-2 text-uppercase fw-semibold">Shift</p>
										<span class="badge bg-success-subtle text-success fs-11" id="payment-status">Shift
											{{ shift }}</span>
									</div>
									<!--end col-->
									<!-- <div class="col-lg-3 col-6">
										<p class="text-muted mb-2 text-uppercase fw-semibold">Taken at</p>
										<h5 class="fs-14 mb-0"><span id="total-amount"> </span></h5>
									</div> -->
									<!--end col-->
								</div>
								<!--end row-->
							</div>
							<!--end card-body-->
						</div><!--end col-->



						<div class="col-lg-12" style="margin-left: 15px;">
							<div class=" border-top border-top-dashed"></div> <br>
							<h4>Mixing</h4>
							<div class="columns">
								<div class="column">

									<table border=0 cellpadding=0 cellspacing=0 width=610 style='border-collapse:
 collapse;table-layout:fixed;width:458pt'>
										<col width=69 style='mso-width-source:userset;mso-width-alt:2523;width:52pt'>
										<col width=78 style='mso-width-source:userset;mso-width-alt:2852;width:59pt'>
										<col width=79 style='mso-width-source:userset;mso-width-alt:2889;width:59pt'>
										<col width=64 span=6 style='width:48pt'>
										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl6721064 width=610 style='border-right:1.0pt solid black;
  height:15.75pt;width:458pt'>Mixing Line A</td>
										</tr>
										<tr height=21 style='mso-height-source:userset;height:15.75pt'>
											<td colspan=3 height=21 class=xl6721064 style='border-right:1.0pt solid black;
  height:15.75pt'>Output I</td>
											<td colspan=3 class=xl6721064 style='border-right:1.0pt solid black;
  border-left:none'>Plan</td>
											<td colspan=3 class=xl6721064 style='border-right:1.0pt solid black;
  border-left:none'>Achievement</td>
										</tr>
										<tr height=21 style='height:15.75pt'>
											<td v-for="index in 3" :key="index" class="xl6321064"
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixiineaoutput[index - 1] !== undefined">{{
													mixiineaoutput[index - 1] }}</span>
												<span v-else>-</span>
											</td>


											<td v-for="index in 3" :key="index" class=xl6321064
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixlineaplan[index - 1] !== undefined">{{ mixlineaplan[index -
													1]
												}}</span>
												<span v-else>-</span>
											</td>


											<td v-for="index in 3" :key="index" class=xl6321064
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixlineaachievement[index - 1] !== undefined">{{
													mixlineaachievement[index - 1] }}</span>
												<span v-else>-</span>
											</td>

										</tr>
										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl7021064 style='border-right:1.0pt solid black;
  height:15.75pt'>Downtime</td>
										</tr>
										<tr height=21 style='height:15.75pt'
											v-for="(adowntime, indexdowntimea) in mixlineadowntimemix"
											:key="indexdowntimea">
											<td height=21 class=xl6321064 style='height:15.75pt;border-top:none'>
												{{ (indexdowntimea + 1) }}</td>
											<td colspan=8 class=xl6421064 style='border-right:1.0pt solid black;
  border-left:none'>{{ adowntime }}</td>
										</tr>


										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl7021064 style='border-right:1.0pt solid black;
  height:15.75pt'>Issue</td>
										</tr>
										<tr height=21 style='height:15.75pt' v-for="(issue, index) in mixlineaissuemix"
											:key="index">
											<td height=21 class=xl6321064 style='height:15.75pt;border-top:none'>{{ index +
												1
											}}</td>
											<td colspan=8 class=xl6421064 style='border-right:1.0pt solid black;
  border-left:none'>{{ issue }}</td>
										</tr>

										<tr height=0 style='display:none'>
											<td width=69 style='width:52pt'></td>
											<td width=78 style='width:59pt'></td>
											<td width=79 style='width:59pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
										</tr>

									</table>

								</div>
								<div class="column">

									<table border=0 cellpadding=0 cellspacing=0 width=610 style='border-collapse:
 collapse;table-layout:fixed;width:458pt'>
										<col width=69 style='mso-width-source:userset;mso-width-alt:2523;width:52pt'>
										<col width=78 style='mso-width-source:userset;mso-width-alt:2852;width:59pt'>
										<col width=79 style='mso-width-source:userset;mso-width-alt:2889;width:59pt'>
										<col width=64 span=6 style='width:48pt'>
										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl6721064 width=610 style='border-right:1.0pt solid black;
  height:15.75pt;width:458pt'>Mixing Line B</td>
										</tr>
										<tr height=21 style='mso-height-source:userset;height:15.75pt'>
											<td colspan=3 height=21 class=xl6721064 style='border-right:1.0pt solid black;
  height:15.75pt'>Output I</td>
											<td colspan=3 class=xl6721064 style='border-right:1.0pt solid black;
  border-left:none'>Plan</td>
											<td colspan=3 class=xl6721064 style='border-right:1.0pt solid black;
  border-left:none'>Achievement</td>
										</tr>
										<tr height=21 style='height:15.75pt'>

											<!-- <td height=21 class=xl6321064 style='height:15.75pt;border-top:none'> <span v-if="mixiineboutput[0] !==undefined">{{mixiineboutput[0]}}</span> <span v-else>-</span></td>
   <td class=xl6321064 style='border-top:none;border-left:none'> <span v-if="mixiineboutput[1] !==undefined">{{ mixiineboutput[1]}}</span> <span v-else>-</span></td>
  <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixiineboutput[2] !==undefined"> {{ mixiineboutput[2]}} </span><span v-else>-</span></td> -->

											<td v-for="index in 3" :key="index" class="xl6321064"
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixiineboutput[index - 1] !== undefined">{{
													mixiineboutput[index - 1] }}</span>
												<span v-else>-</span>
											</td>


											<td v-for="index in 3" :key="index" class=xl6321064
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixlinebplan[index - 1] !== undefined">{{ mixlinebplan[index -
													1]
												}}</span>
												<span v-else>-</span>
											</td>


											<td v-for="index in 3" :key="index" class=xl6321064
												:style="{ borderTop: index > 1 ? 'none' : '', borderLeft: index > 1 ? 'none' : '' }">
												<span v-if="mixlinebachievement[index - 1] !== undefined">{{
													mixlinebachievement[index - 1] }}</span>
												<span v-else>-</span>
											</td>


											<!--  
  <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixiinebplan[1] !==undefined"> {{ mixiinebplan[1]}}</span><span v-else>-</span> </td>
  <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixiinebplan[2] !==undefined"> {{ mixiinebplan[2]}}</span><span v-else>-</span> </td>
 <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixlinebachievement[0] !==undefined"> {{ mixlinebachievement[0]}}</span><span v-else>-</span> </td>
  <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixlinebachievement[1] !==undefined"> {{ mixlinebachievement[1]}}</span><span v-else>-</span> </td>
  <td class=xl6321064 style='border-top:none;border-left:none'><span v-if="mixlinebachievement[2] !==undefined"> {{ mixlinebachievement[2]}}</span><span v-else>-</span> </td>  -->
										</tr>
										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl7021064 style='border-right:1.0pt solid black;
  height:15.75pt'>Downtime</td>

										</tr>
										<tr height=21 style='height:15.75pt'
											v-for="(bdowntime, indexdowntimeb) in mixlinebdowntimemix"
											:key="indexdowntimeb">
											<td height=21 class=xl6321064 style='height:15.75pt;border-top:none'>
												{{ (indexdowntimeb + 1) }}</td>
											<td colspan=8 class=xl6421064 style='border-right:1.0pt solid black;
  border-left:none'>{{ bdowntime }}</td>
										</tr>


										<tr height=21 style='height:15.75pt'>
											<td colspan=9 height=21 class=xl7021064 style='border-right:1.0pt solid black;
  height:15.75pt'>Issue</td>
										</tr>
										<tr height=21 style='height:15.75pt' v-for="(issue, index) in mixlinebissuemix"
											:key="index">
											<td height=21 class=xl6321064 style='height:15.75pt;border-top:none'>{{ index +
												1
											}}</td>
											<td colspan=8 class=xl6421064 style='border-right:1.0pt solid black;
  border-left:none'>{{ issue }}</td>
										</tr>

										<tr height=0 style='display:none'>
											<td width=69 style='width:52pt'></td>
											<td width=78 style='width:59pt'></td>
											<td width=79 style='width:59pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
											<td width=64 style='width:48pt'></td>
										</tr>

									</table>

								</div>
							</div>


						</div>
						<div class="col-lg-12" style="margin-left: 15px;">
							<div class=" border-top border-top-dashed"></div> <br>
							<h4>Holding Tank</h4>
							<div class="columns is-multiline">
								<div class="column is-6">
									<HoldingTank TankNo="T81/82/83" :totalwip="holdingtank1totalwip"
										:downtime="downtimeholdingtank1" :issue="isssueholdingtank1" :isholdingtank="true">
									</HoldingTank>
								</div>
								<div class="column is-6">
									<HoldingTank TankNo="T91/92/93" :totalwip="holdingtank2totalwip"
										:downtime="downtimeholdingtank2" :issue="isssueholdingtank2" :isholdingtank="true">
									</HoldingTank>
								</div>
								<div class="column is-6">
									<HoldingTank TankNo="T161/162/163" :totalwip="holdingtank3totalwip"
										:downtime="downtimeholdingtank3" :issue="isssueholdingtank3" :isholdingtank="true">
									</HoldingTank>
								</div>
							</div>

							<br>


							<br>
							<div class=" border-top border-top-dashed"></div> <br>
							<h4>Sterilizer</h4>

							<br>
							<HoldingTank TankNo="ST1/AT1" :status="sterilizer1status" :downtime="downtimesterilizer1"
								:issue="isssuesterilizer1" :isholdingtank="false">
							</HoldingTank>
							<br>
							<HoldingTank TankNo="ST2/AT2" :status="sterilizer2status" :downtime="downtimesterilizer2"
								:issue="isssuesterilizer2" :isholdingtank="false">
							</HoldingTank>
							<br>
							<HoldingTank TankNo="ST3/AT3" :status="sterilizer3status" :downtime="downtimesterilizer3"
								:issue="isssuesterilizer3" :isholdingtank="false">
							</HoldingTank>

							<br>


							<div class="card-body p-4 border-top border-top-dashed">
								<div class="row g-3">
									<div class="col-6">

									</div>
									<!--end col-->
									<div class="col-6">
										<h6 class="text-muted text-uppercase fw-semibold mb-3">Reported by</h6>
										<br>
										<br>
										<h6 class="mb-0"><span class="text-muted fw-normal">Mixing: </span><span
												id="contact-no"> {{ mixreportedby }}</span></h6>
										<br>
										<h6 class="mb-0"><span class="text-muted fw-normal">Holding Tank: </span><span
												id="contact-no"> {{ holdreportedby }}</span></h6>
										<br>
										<h6 class="mb-0"><span class="text-muted fw-normal">Sterilizer: </span><span
												id="contact-no"> {{ sterilizerreportedby }}</span></h6>
									</div>
									<!--end col-->
								</div>
								<!--end row-->
							</div>
							<!--end card-body-->
						</div><!--end col-->
						<div class="col-lg-12">
							<div class="card-body p-4">

								<div class="hstack gap-2 justify-content-end d-print-none mt-4">
									<a href="javascript:window.print()" class="btn btn-success"><i
											class="ri-printer-line align-bottom me-1"></i> Print</a>
									
								</div>
							</div>
							<!--end card-body-->
						</div><!--end col-->
					</div><!--end row-->
				</div>
				<!--end card-->
			</div>
			<!--end col-->
		</div>
	</div>
</template>

<script>
import { supabase } from '../../supabase/supabase'
import * as moment from 'moment'
import HoldingTank from './ShiftlyComponent/HoldingTank.vue'
import { useAuth } from '../../state/auth'
// import Vue from 'vue'
// import VueHtmlToPaper from 'vue-html-to-paper';
// const printoptions = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css'
//   ],
//   timeout: 1000, // default timeout before the print window appears	
//   autoClose: false, // if false, the window will not close after printing
//   windowTitle: window.document.title, // override the window title
// }
// Vue.use(VueHtmlToPaper, printoptions);
export default {
	components: {
		HoldingTank
	},

	beforeMount() {   
        this.authState.isUserActive();
        this.authState.setLayout(false)
        
    },
	mounted() {

		console.log(this.mixiineboutput[0])
		if (this.mixiineboutput[0] === 0) {

			console.log('not recognized')
		}

	},
	data() {
		return {
			authState: useAuth(),


			status: '',
			hasError: false,
			loading: true,

			shift: 0,
			date: null,
			MixingData: [],
			mixlinea: '',
			mixiineaoutput: [],
			mixlineaplan: [],
			mixlineaachievement: [],
			mixiineboutput: [],
			mixlinebplan: [],
			mixlinebachievement: [],
			mixlineadowntimemix: [],
			mixlineaissuemix: [],
			mixlinebdowntimemix: [],
			mixlinebissuemix: [],
			mixreportedby: '',
			/* --------------------------------- holding -------------------------------- */
			holdreportedby: '',
			holdingtank1: '',
			holdingtank1totalwip: '',
			downtimeholdingtank1: [],
			isssueholdingtank1: [],


			holdingtank2: '',
			holdingtank2totalwip: '',
			downtimeholdingtank2: [],
			isssueholdingtank2: [],

			holdingtank3: '',
			holdingtank3totalwip: '',
			downtimeholdingtank3: [],
			isssueholdingtank3: [],

			/* ------------------------------- sterilizer ------------------------------- */
			sterilizerreportedby: '',
			sterilizer1: '',
			sterilizer1status: '',
			downtimesterilizer1: [],
			isssuesterilizer1: [],


			sterilizer2: '',
			sterilizer2status: '',
			downtimesterilizer2: [],
			isssuesterilizer2: [],

			sterilizer3: '',
			sterilizer3status: '',
			downtimesterilizer3: [],
			isssuesterilizer3: [],

		}
	},
	errorCaptured(err, vm, info) {
		// Log the error (you can also send it to a logging service)
		console.error('Error captured:', err, vm, info);
		// Set the hasError flag to true
		this.hasError = true;
		// Prevent propagation of the error to the parent component
		return false;
	},
	methods: {
		async printnow() {
			await this.$htmlToPaper('printarea');
		},
		async setStatus() {
			this.status = "oke"
		},
		// 		 filterArrayByTankNo(arr, prop, value ){
		//   return arr.filter(x => x[prop] === value);
		// },
		fillArrayWithZeros(arr) {
			while (arr.length < 3) {
				arr.push(0);
			}
			return arr;
		},
		ensureArrayLength(array, length, defaultValue) {
			// Clone the original array or create a new one with the default value
			const newArray = [...array];

			// Fill the array with the default value if its length is less than the desired length
			while (newArray.length < length) {
				newArray.push(defaultValue);
			}

			// Return the modified array
			return newArray;
		},
		async generate() {

			/* --------------------------------- mixing --------------------------------- */
			try {
				this.loading = true
				const thedate = moment(this.date).format("YYYY-MM-DD")
				console.log(thedate)
				const { data: rpc, error: delerror } = await supabase.from('Mixing').select()
					.eq('shift', this.shift)
					.eq('date', thedate)

				var mixnik = '';

				rpc.forEach(element => {
					mixnik = element.nik;
					if (element.line == '1') {


						this.mixiineaoutput.push(element.output)


						this.mixlineaplan.push(element.plan)
						this.mixlineaachievement.push(element.achievement)


					} else {
						this.mixiineboutput.push(element.output)


						this.mixlinebplan.push(element.plan)
						this.mixlinebachievement.push(element.achievement)

					}

				});



				const { data } = await supabase.rpc('get_user_name', { 'nik_param': mixnik })
				this.mixreportedby = data;

				this.fillArrayWithZeros(this.mixlinebplan)
				this.loading = false
				console.log(rpc)
				console.log(delerror)

			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")

				const { data: rpc, error: delerror } = await supabase.from('MixingDowntime').select()
					.eq('shift', this.shift)
					.eq('date', thedate)

				rpc.forEach(element => {

					if (element.line == '1') {
						this.mixlineadowntimemix.push(element.Downtime)
					}
					else {
						this.mixlinebdowntimemix.push(element.Downtime)
					}
				});

				console.log(rpc)
				console.log(delerror)
			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")
				console.log(thedate)
				const { data: rpc, error: delerror } = await supabase.from('MixingIssue').select()
					.eq('shift', this.shift)
					.eq('date', thedate)

				rpc.forEach(element => {
					if (element.line == '1') {
						this.mixlineaissuemix.push(element.Issues)
					}
					else {
						this.mixlinebissuemix.push(element.Issues)
					}
				});
				console.log(rpc)
				console.log(delerror)
			} catch (error) {

				console.log(error)
			}


			/* ------------------------------ holding tank ------------------------------ */


			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")

				const { data: rpc, error: delerror } = await supabase.from('HoldingTank').select()
					.eq('shift', this.shift)
					.eq('date', thedate)
				var holdnik = '';
				rpc.forEach(element => {
					holdnik = element.nik
					if (element.TankNo == 'T81/82/83') {
						this.holdingtank1totalwip = element.TotalWIP;
					}
					else if (element.TankNo == 'T91/92/93') {
						this.holdingtank2totalwip = element.TotalWIP;
					}
					else if (element.TankNo == 'T161/162/163') {
						this.holdingtank3totalwip = element.TotalWIP;
					}
				});
				const { data } = await supabase.rpc('get_user_name', { 'nik_param': holdnik })
				this.holdreportedby = data;


				//holdingtank1totalwip
				console.log(rpc)
				console.log(delerror)
			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")

				const { data: rpc, error: delerror } = await supabase.from('HoldingTankDowntime').select()
					.eq('shift', this.shift)
					.eq('date', thedate)

				rpc.forEach(element => {
					if (element.TankNo == 'T81/82/83') {
						this.downtimeholdingtank1.push(element.Downtime);
					}
					else if (element.TankNo == 'T91/92/93') {
						this.downtimeholdingtank2.push(element.Downtime);
					}
					else if (element.TankNo == 'T161/162/163') {
						this.downtimeholdingtank3.push(element.Downtime);
					}
				});

				console.log(rpc)
				console.log(delerror)
			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")
				console.log(thedate)
				const { data: rpc, error: delerror } = await supabase.from('HoldingTankIssue').select()
					.eq('shift', this.shift)
					.eq('date', thedate)
				rpc.forEach(element => {
					if (element.TankNo == 'T81/82/83') {
						this.isssueholdingtank1.push(element.Issues);
					}
					else if (element.TankNo == 'T91/92/93') {
						this.isssueholdingtank2.push(element.Issues);
					}
					else if (element.TankNo == 'T161/162/163') {
						this.isssueholdingtank3.push(element.Issues);
					}
				});


				console.log(rpc)
				console.log(delerror)
			} catch (error) {

				console.log(error)
			}


			/* ------------------------------ Sterilizer ------------------------------ */


			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")
				console.log(thedate)
				const { data: rpc, error: delerror } = await supabase.from('Sterilizer').select()
					.eq('shift', this.shift)
					.eq('date', thedate)
				var sterilizernik = '';
				rpc.forEach(element => {
					sterilizernik = element.nik
					if (element.SterilizerNo == 'ST1/AT1') {
						this.sterilizer1status = element.status;
					}
					else if (element.SterilizerNo == 'ST2/AT2') {
						this.sterilizer2status = element.status;
					}
					else if (element.SterilizerNo == 'ST3/AT3') {
						this.sterilizer3status = element.status;
					}
				});

				const { data } = await supabase.rpc('get_user_name', { 'nik_param': sterilizernik })
				this.sterilizerreportedby = data;


				//holdingtank1totalwip
				console.log(rpc)
				console.log(delerror)
			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")

				const { data: rpc, error: delerror } = await supabase.from('SterilizerDowntime').select()
					.eq('shift', this.shift)
					.eq('date', thedate)

				rpc.forEach(element => {
					if (element.SterilizerNo == 'ST1/AT1') {
						this.downtimesterilizer1.push(element.Downtime);
					}
					else if (element.SterilizerNo == 'ST2/AT2') {
						this.downtimesterilizer2.push(element.Downtime);
					}
					else if (element.SterilizerNo == 'ST3/AT3') {
						this.downtimesterilizer3.push(element.Downtime);
					}
				});

				console.log(rpc)
				console.log(delerror)
			} catch (errord) {
				console.log(errord)
			}

			try {
				const thedate = moment(this.date).format("YYYY-MM-DD")
				console.log(thedate)
				const { data: rpc, error: delerror } = await supabase.from('SterilizerIssue').select()
					.eq('shift', this.shift)
					.eq('date', thedate)
				rpc.forEach(element => {
					if (element.SterilizerNo == 'ST1/AT1') {
						this.isssuesterilizer1.push(element.Issues);
					}
					else if (element.SterilizerNo == 'ST2/AT2') {
						this.isssuesterilizer2.push(element.Issues);
					}
					else if (element.SterilizerNo == 'ST3/AT3') {
						this.isssuesterilizer3.push(element.Issues);
					}
				});


				console.log(rpc)
				console.log(delerror)
			} catch (error) {

				console.log(error)
			}


			this.$forceUpdate();

		}
	},
	computed: {
		Mixiineboutput() {
			return this.ensureArrayLength(this.mixiineboutput, 3, 0);
		},
		Mixiinebplan() {
			return this.ensureArrayLength(this.mixiinebplan, 3, 0);
		},
		Mixlinebachievement() {
			return this.ensureArrayLength(this.mixlinebachievement, 3, 0);
		}

	}
}
</script>

<style>
@media print {
	.hidden-print {
		display: none !important;
	}
}
</style>
<style lang="css" scopned>
.timeline {
	width: 100%;
	height: 100px;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.timeline .events {
	position: relative;
	background-color: #606060;
	height: 3px;
	width: 100%;
	border-radius: 4px;
	margin: 5em 0;
}

.timeline .events ol {
	margin: 0;
	padding: 0;
	text-align: center;
}

.timeline .events ul {
	list-style: none;
}

.timeline .events ul li {
	display: inline-block;
	width: 24.65%;
	margin: 0;
	padding: 0;
}

.timeline .events ul li a {
	font-family: 'Arapey', sans-serif;
	font-style: italic;
	font-size: 1.25em;
	color: #606060;
	text-decoration: none;
	position: relative;
	top: -32px;
}

.timeline .events ul li a:after {
	content: '';
	position: absolute;
	bottom: -25px;
	left: 50%;
	right: auto;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	border: 3px solid #606060;
	background-color: #fff;
	transition: 0.3s ease;
	transform: translateX(-50%);
}

.timeline .events ul li a:hover::after {
	background-color: #194693;
	border-color: #194693;
}

.timeline .events ul li a.selected:after {
	background-color: #194693;
	border-color: #194693;
}

.events-content {
	width: 100%;
	height: 100px;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: left;
}

.events-content li {
	display: none;
	list-style: none;
}

.events-content li.selected {
	display: initial;
}

.events-content li h2 {
	font-family: 'Frank Ruhl Libre', serif;
	font-weight: 500;
	color: #919191;
	font-size: 2.5em;
}
</style>


<style id="Book1_21064_Styles">
.xl1521064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: general;
	vertical-align: bottom;
	mso-background-source: auto;
	mso-pattern: auto;
	white-space: nowrap;
}

.xl6321064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: general;
	vertical-align: bottom;
	border: 1.0pt solid;
	mso-background-source: auto;
	mso-pattern: auto;
	white-space: nowrap;
}

.xl6421064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: 1.0pt solid;
	mso-background-source: auto;
	mso-pattern: auto;
	white-space: nowrap;
}

.xl6521064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: none;
	mso-background-source: auto;
	mso-pattern: auto;
	white-space: nowrap;
}

.xl6621064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: 1.0pt solid;
	border-bottom: 1.0pt solid;
	border-left: none;
	mso-background-source: auto;
	mso-pattern: auto;
	white-space: nowrap;
}

.xl6721064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: 1.0pt solid;
	background: #00B0F0;
	mso-pattern: black none;
	white-space: nowrap;
}

.xl6821064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: none;
	background: #00B0F0;
	mso-pattern: black none;
	white-space: nowrap;
}

.xl6921064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: 1.0pt solid;
	border-bottom: 1.0pt solid;
	border-left: none;
	background: #00B0F0;
	mso-pattern: black none;
	white-space: nowrap;
}

.xl7021064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: 1.0pt solid;
	background: #FFC000;
	mso-pattern: black none;
	white-space: nowrap;
}

.xl7121064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: none;
	border-bottom: 1.0pt solid;
	border-left: none;
	background: #FFC000;
	mso-pattern: black none;
	white-space: nowrap;
}

.xl7221064 {
	padding-top: 1px;
	padding-right: 1px;
	padding-left: 1px;
	mso-ignore: padding;
	color: black;
	font-size: 11.0pt;
	font-weight: 700;
	font-style: normal;
	text-decoration: none;
	font-family: Calibri, sans-serif;
	mso-font-charset: 0;
	mso-number-format: General;
	text-align: center;
	vertical-align: bottom;
	border-top: 1.0pt solid;
	border-right: 1.0pt solid;
	border-bottom: 1.0pt solid;
	border-left: none;
	background: #FFC000;
	mso-pattern: black none;
	white-space: nowrap;
}</style>

