import { render, staticRenderFns } from "./ErrorAuth.vue?vue&type=template&id=52dd65dc&scoped=true&"
import script from "./ErrorAuth.vue?vue&type=script&lang=js&"
export * from "./ErrorAuth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dd65dc",
  null
  
)

export default component.exports