<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

      
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <a href="#" class="d-inline-block auth-logo">
                                    <img src="assets/logo.png" alt="" height="50">
                                </a>
                            </div>
                            <p class="mt-3 fs-15 fw-medium">KeyIn System</p>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">Forgot Password?</h5>
                                    <p class="text-muted">Reset password</p>

                                    <lord-icon src="../../../rhvddzym.json" trigger="loop" colors="primary:#0ab39c"
                                        class="avatar-xl">
                                    </lord-icon>

                                </div>

                                <div class="alert border-0 alert-warning text-center mb-2 mx-2" role="alert">
                                    Enter your email and instructions will be sent to you <br>
                                    If you are not find the email, check at <strong>Junk or Spam Mailbox!</strong>
                                </div>
                                <div class="p-2">
                                    <form>
                                        <div class="mb-4">
                                            <label class="form-label">Email</label>
                                            <input type="email" class="form-control" v-model="email" id="email" placeholder="Enter Your Email">
                                        </div>

                                        <div class="text-center mt-4">
                                            <a class="btn btn-success w-100" @click="GiveReset">Send Reset Link</a>
                                        </div>
                                    </form><!-- end form -->
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                        <div class="mt-4 text-center">
                            <p class="mb-0">Wait, I remember my password... <a href="/login"
                                    class="fw-semibold text-primary text-decoration-underline"> Click here </a> </p>
                        </div>

                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">&copy;
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
    <!-- <input type="text" placeholder="email" v-model="email" class="form-control">

        <button @click="GiveReset">Kirim</button>

        <br>
        <br>

        <input type="text" placeholder="newPassword" v-model="password" class="form-control">
        <button type="button" @click="Reset">Reset</button> -->
</template>

<script>
import { supabase } from '../../supabase/supabase';
export default {
    data() {
        return {
            email: '',
          
        }
    },
    created() {
        this.authState.setLayout(false)
    },
    methods: {
        async GiveReset() {
            //      if(this.email!=null){
            try {
                 await supabase.auth.resetPasswordForEmail(this.email, {
                redirectTo: process.env.OWNURI + '/auth',
            })
            this.$router.push('/RecSent');
            } catch (error) {
                 this.$router.push('/AuthError');
            }
               
            //             }
        },

    }
}
</script>

<style lang="scss" scoped></style>