<template>
    <!-- auth-page wrapper -->
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>
        <!-- auth-page content -->
        <div class="auth-page-content overflow-hidden pt-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card overflow-hidden">
                            <div class="row g-0">
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <a href="#" class="d-block">
                                                    <img src="assets/logo.png" alt="" height="40">
                                                </a>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div id="qoutescarouselIndicators" class="carousel slide"
                                                    data-bs-ride="carousel">
                                                    <div class="carousel-indicators">
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="0" class="active" aria-current="true"
                                                            aria-label="Slide 1"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                        <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                            data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    </div>
                                         
                                                    <div class="carousel-inner text-center text-white pb-5">
                                                        <div class="carousel-item active">
                                                            <p class="fs-15 fst-italic">"Automation empowers workers for unprecedented efficiency and innovation."</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">"Sets a new standard of precision and speed in every sector"</p>
                                                        </div>
                                                        <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">"Elevates craftsmanship with unmatched precision, reshaping industries for limitless possibility "</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end carousel -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->

                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4">
                                        <div>
                                            <h5 class="text-primary">Welcome !</h5>
                                            <p class="text-muted">Sign Up to KeyIn System.</p>
                                        </div>

                                        <div class="mt-4">


                                            <FormulateForm v-model="formValues" @submit="submitHandler">


                                                <div class="mb-3">


                                                    <FormulateInput name="nik" type="number" label="NIK"
                                                        placeholder="Your NIK" validation="required"
                                                        label-class="form-label" input-class="form-control" />
                                                </div>

                                                <div class="mb-3">
                                                    <FormulateInput validation="required" type="email" name="email"
                                                        label-class="form-label" input-class="form-control" label="Email" />

                                                </div>

                                                <div class="mb-3">

                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <FormulateInput validation="required" type="text"
                                                            label="Nama Lengkap" name="fullname" label-class="form-label"
                                                            input-class="form-control" />

                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <FormulateInput validation="required" label="Nama Panggilan" type="text"
                                                        name="nickname" label-class="form-label"
                                                        input-class="form-control" />

                                                </div>

                                                <div class="mb-3">
                                                    <div class="input-group">
                                                        <label class="input-group-text">Jenis Kelamin</label>
                                                        <select class="form-select" v-model="formValues.gender">
                                                            <option selected="">Choose...</option>
                                                            <option value="Pria">Laki-Laki</option>
                                                            <option value="Wanita">Wanita</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <div class="input-group">
                                                        <label class="input-group-text">Department</label>
                                                        <select class="form-select" v-model="formValues.department">
                                                            <option id='-'>-</option>
                                                            <option id='Corporate Research Development'>Corporate Research
                                                                Development</option>
                                                            <option id='FA_Accounting'>FA_Accounting</option>
                                                            <option id='FA_IT'>FA_IT</option>
                                                            <option id='FA_Purchasing'>FA_Purchasing</option>
                                                            <option id='FA_Tax'>FA_Tax</option>
                                                            <option id='FA_Transport & Shipping'>FA_Transport & Shipping
                                                            </option>
                                                            <option id='FA_Treasury'>FA_Treasury</option>
                                                            <option id='Factory Management'>Factory Management</option>
                                                            <option id='gr34'>gr34</option>
                                                            <option id='HR_Administration'>HR_Administration</option>
                                                            <option id='HR_Compensation & Benefit'>HR_Compensation & Benefit
                                                            </option>
                                                            <option id='HR_General Affairs'>HR_General Affairs</option>
                                                            <option id='HR_HR Unit'>HR_HR Unit</option>
                                                            <option id='HR_Industrial Relation'>HR_Industrial Relation
                                                            </option>
                                                            <option id='HR_Organization Development'>HR_Organization
                                                                Development</option>
                                                            <option id='Operation'>Operation</option>
                                                            <option id='OPR_Can Making'>OPR_Can Making</option>
                                                            <option id='OPR_Engineering'>OPR_Engineering</option>
                                                            <option id='OPR_Factory Management'>OPR_Factory Management
                                                            </option>
                                                            <option id='OPR_Fresh Milk Development & Services'>OPR_Fresh
                                                                Milk Development & Services</option>
                                                            <option id='OPR_PPIC/Supply Chain'>OPR_PPIC/Supply Chain
                                                            </option>
                                                            <option id='OPR_Production Liquid'>OPR_Production Liquid
                                                            </option>
                                                            <option id='OPR_Production SCM'>OPR_Production SCM</option>
                                                            <option id='OPR_Quality Assurance'>OPR_Quality Assurance
                                                            </option>
                                                            <option id='OPR_Warehouse'>OPR_Warehouse</option>
                                                            <option id='PPIC'>PPIC</option>

                                                        </select>
                                                    </div>



                                                </div>

                                                <div class="mb-3">
                                                    <div class="input-group">
                                                        <label class="input-group-text">Position</label>
                                                        <select class="form-select" v-model="formValues.position">
                                                            <option id='-'>-</option>
                                                            <option id='A/R Invoicing Staff Indomilk'>A/R Invoicing Staff
                                                                Indomilk</option>
                                                            <option id='Area Engineering Supervisor'>Area Engineering
                                                                Supervisor</option>
                                                            <option id='Area Engineering Supervisor Liquid'>Area Engineering
                                                                Supervisor Liquid</option>
                                                            <option id='ASP Developer'>ASP Developer</option>
                                                            <option id='ASRS Operator'>ASRS Operator</option>
                                                            <option id='ASRS Technician'>ASRS Technician</option>
                                                            <option id='Autoclave Operator'>Autoclave Operator</option>
                                                            <option id='Automation Engineer'>Automation Engineer</option>
                                                            <option id='Boiler Operator'>Boiler Operator</option>
                                                            <option id='Boiler, Utility & WTP Supervisor'>Boiler, Utility &
                                                                WTP Supervisor</option>
                                                            <option id='Building Maintenance & Workshop SPV'>Building
                                                                Maintenance & Workshop SPV</option>
                                                            <option id='Building Maintenance Operator'>Building Maintenance
                                                                Operator</option>
                                                            <option id='Can Body Making Operator'>Can Body Making Operator
                                                            </option>
                                                            <option id='Can Making Admin'>Can Making Admin</option>
                                                            <option id='Can Making Manager'>Can Making Manager</option>
                                                            <option id='Can Making Supervisor'>Can Making Supervisor
                                                            </option>
                                                            <option id='Can Making Technician'>Can Making Technician
                                                            </option>
                                                            <option id='Cashier'>Cashier</option>
                                                            <option id='Checker'>Checker</option>
                                                            <option id='CIP Champion'>CIP Champion</option>
                                                            <option id='CIP Operator'>CIP Operator</option>
                                                            <option id='Continuous Improvement Manager'>Continuous
                                                                Improvement Manager</option>
                                                            <option id='Cost Accounting Staff'>Cost Accounting Staff
                                                            </option>
                                                            <option id='Cost Accounting Supervisor'>Cost Accounting
                                                                Supervisor</option>
                                                            <option id='CTO'>CTO</option>
                                                            <option id='Cut Open Helper'>Cut Open Helper</option>
                                                            <option id='Document Controller'>Document Controller</option>
                                                            <option id='Electrical & Instrument & ASRS Engineer'>Electrical
                                                                & Instrument & ASRS Engineer</option>
                                                            <option id='Electrical & Instrument Technician'>Electrical &
                                                                Instrument Technician</option>
                                                            <option id='Engineering Admin'>Engineering Admin</option>
                                                            <option id='Engineering Can Making Supervisor'>Engineering Can
                                                                Making Supervisor</option>
                                                            <option id='Engineering Manager'>Engineering Manager</option>
                                                            <option id='Factory Manager'>Factory Manager</option>
                                                            <option id='Field Inspector Safety'>Field Inspector Safety
                                                            </option>
                                                            <option id='Field Staff Officer (FSO)'>Field Staff Officer (FSO)
                                                            </option>
                                                            <option id='Fiiling Operator Sachet'>Fiiling Operator Sachet
                                                            </option>
                                                            <option id='Filling Operator'>Filling Operator</option>
                                                            <option id='Filling Operator Can'>Filling Operator Can</option>
                                                            <option id='Filling Operator Pouch'>Filling Operator Pouch
                                                            </option>
                                                            <option id='Filling Operator Sachet'>Filling Operator Sachet
                                                            </option>
                                                            <option id='Finance & Accounting Manager'>Finance & Accounting
                                                                Manager</option>
                                                            <option id='Flash Cooler Operator'>Flash Cooler Operator
                                                            </option>
                                                            <option id='Formulating Operator'>Formulating Operator</option>
                                                            <option id='Freshmilk Operator'>Freshmilk Operator</option>
                                                            <option id='Freshmilk Reception Operator'>Freshmilk Reception
                                                                Operator</option>
                                                            <option id='GA Services Staff'>GA Services Staff</option>
                                                            <option id='General Accounting Staff'>General Accounting Staff
                                                            </option>
                                                            <option id='General Accounting Supervisor'>General Accounting
                                                                Supervisor</option>
                                                            <option id='GMM'>GMM</option>
                                                            <option id='GMP & Hygiene Inspector'>GMP & Hygiene Inspector
                                                            </option>
                                                            <option id='Grinder Operator'>Grinder Operator</option>
                                                            <option id='HD'>HD</option>
                                                            <option id='HOD'>HOD</option>
                                                            <option id='HR & GA Manager'>HR & GA Manager</option>
                                                            <option id='HR Supervisor'>HR Supervisor</option>
                                                            <option id='Incoming & Physchem Supervisor'>Incoming & Physchem
                                                                Supervisor</option>
                                                            <option id='Industrial Relation Officer'>Industrial Relation
                                                                Officer</option>
                                                            <option id='Industrial Relation Staff'>Industrial Relation Staff
                                                            </option>
                                                            <option id='Inventory Officer'>Inventory Officer</option>
                                                            <option id='IP'>IP</option>
                                                            <option id='IT Staff'>IT Staff</option>
                                                            <option id='IT Supervisor'>IT Supervisor</option>
                                                            <option id='Labeller Operator'>Labeller Operator</option>
                                                            <option id='Leak Detector Operator'>Leak Detector Operator
                                                            </option>
                                                            <option id='Lid Feeder Operator'>Lid Feeder Operator</option>
                                                            <option id='Loading / unloading Operator'>Loading / unloading
                                                                Operator</option>
                                                            <option id='Maints. Planner & Spare Parts Controller'>Maints.
                                                                Planner & Spare Parts Controller</option>
                                                            <option id='MCC Admin'>MCC Admin</option>
                                                            <option id='MCC Coordinator'>MCC Coordinator</option>
                                                            <option id='Mechanical Technician'>Mechanical Technician
                                                            </option>
                                                            <option id='Microbiology Supervisor'>Microbiology Supervisor
                                                            </option>
                                                            <option id='Mixing Operator'>Mixing Operator</option>
                                                            <option id='OD Supervisor'>OD Supervisor</option>
                                                            <option id='Oil Handling Operator'>Oil Handling Operator
                                                            </option>
                                                            <option id='Operator Can O Mat'>Operator Can O Mat</option>
                                                            <option id='Operator End O Mat'>Operator End O Mat</option>
                                                            <option id='Packer'>Packer</option>
                                                            <option id='Packing Opeator'>Packing Opeator</option>
                                                            <option id='Packing Operator'>Packing Operator</option>
                                                            <option id='Pall Depall Operator'>Pall Depall Operator</option>
                                                            <option id='Payroll & Comben Staff'>Payroll & Comben Staff
                                                            </option>
                                                            <option id='post0123'>post0123</option>
                                                            <option id='PPIC Admin'>PPIC Admin</option>
                                                            <option id='PPIC Manager'>PPIC Manager</option>
                                                            <option id='PPIC Staff'>PPIC Staff</option>
                                                            <option id='PPIC Supervisor'>PPIC Supervisor</option>
                                                            <option id='Production Admin'>Production Admin</option>
                                                            <option id='Production Liquid Manager'>Production Liquid Manager
                                                            </option>
                                                            <option id='Production Manager'>Production Manager</option>
                                                            <option id='Production SCM Manager'>Production SCM Manager
                                                            </option>
                                                            <option id='Production Stock Keeper'>Production Stock Keeper
                                                            </option>
                                                            <option id='Production Supervisor'>Production Supervisor
                                                            </option>
                                                            <option id='Project Support Manager'>Project Support Manager
                                                            </option>
                                                            <option id='Purchasing Manager'>Purchasing Manager</option>
                                                            <option id='Purchasing Staff'>Purchasing Staff</option>
                                                            <option id='QA  Incoming RM/PM'>QA Incoming RM/PM</option>
                                                            <option id='QA Admin'>QA Admin</option>
                                                            <option id='QA Analyst  Physchem'>QA Analyst Physchem</option>
                                                            <option id='QA Analyst Microbiology'>QA Analyst Microbiology
                                                            </option>
                                                            <option id='QA Calibration and Validation'>QA Calibration and
                                                                Validation</option>
                                                            <option id='QA Manager'>QA Manager</option>
                                                            <option id='QA Product Supervisor'>QA Product Supervisor
                                                            </option>
                                                            <option id='QC Inline Supervisor'>QC Inline Supervisor</option>
                                                            <option id='QC Inspector'>QC Inspector</option>
                                                            <option id='QFS Supervisor'>QFS Supervisor</option>
                                                            <option id='Quality Assurance'>Quality Assurance</option>
                                                            <option id='Recruitment & Training Staff'>Recruitment & Training
                                                                Staff</option>
                                                            <option id='Robotic Palletizing Operator'>Robotic Palletizing
                                                                Operator</option>
                                                            <option id='Secretary to Chief Technical Officer'>Secretary to
                                                                Chief Technical Officer</option>
                                                            <option id='Secretary to Factory Manager'>Secretary to Factory
                                                                Manager</option>
                                                            <option id='Senior Stock Keeper'>Senior Stock Keeper</option>
                                                            <option id='Sortir'>Sortir</option>
                                                            <option id='Staff'>Staff</option>
                                                            <option id='Sterilizer Operator'>Sterilizer Operator</option>
                                                            <option id='Stock Keeper RM/PM'>Stock Keeper RM/PM</option>
                                                            <option id='Stock Keeper Sparepart'>Stock Keeper Sparepart
                                                            </option>
                                                            <option id='Supervisor'>Supervisor</option>
                                                            <option id='Supervisor CRD'>Supervisor CRD</option>
                                                            <option id='Tax Staff'>Tax Staff</option>
                                                            <option id='Technician'>Technician</option>
                                                            <option id='Tipper'>Tipper</option>
                                                            <option id='Transport & Shipping Staff'>Transport & Shipping
                                                                Staff</option>
                                                            <option id='Utility & WTP Operator'>Utility & WTP Operator
                                                            </option>
                                                            <option id='Warehouse Admin'>Warehouse Admin</option>
                                                            <option id='Warehouse Manager'>Warehouse Manager</option>
                                                            <option id='Warehouse Supervisor (RM/PM/FG)'>Warehouse
                                                                Supervisor (RM/PM/FG)</option>
                                                            <option id='Warehouse Supervisor Sparepart'>Warehouse Supervisor
                                                                Sparepart</option>
                                                            <option id='Workshop Operator'>Workshop Operator</option>
                                                            <option id='Wrapping Operator'>Wrapping Operator</option>
                                                            <option id='WWTP Operator'>WWTP Operator</option>
                                                            <option id='WWTP Staff'>WWTP Staff</option>

                                                        </select>
                                                    </div>
<br><br>

                                                    <div class="mb-3">
                                                        <FormulateInput label="Password" validation="required|min:8"
                                                            id="password-input" type="password" name="password"
                                                            label-class="form-label"
                                                            input-class="form-control pe-5 password-input" />

                                                        <!-- <input type="password" class="form-control pe-5 password-input"
                                                            placeholder="Enter password" > -->

                                                    </div>
                                                </div>






                                                <div class="mt-4">
                                                    <FormulateInput type="submit" label-class="form-label"
                                                        input-class="btn btn-success w-100" label="Register Now" />
                                                    <!-- 
                                                    <button class="btn btn-success w-100" type="submit">Sign In</button> -->
                                                </div>
                                            </FormulateForm>
                                            <!-- <button class="btn btn-danger" @click="getnik()">delete user</button> -->
                                        </div>

                                        <div class="mt-5 text-center">
                                            <p class="mb-0">Already have an account ? <a href="/login"
                                                    class="fw-semibold text-primary text-decoration-underline"> Signin</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!-- end row -->
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->

                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0">&copy;

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
        <a-input v-model="value" placeholder="Basic usage" />
    </div>
    <!-- end auth-page-wrapper -->
</template>

<script>
//import Vue from 'vue'
import { useAuth } from '../../state/auth';

import { supabase, supabaseadmin } from '../../supabase/supabase'
export default {
    data() {
        return {
            authState: useAuth(),
            formValues: {},
            value1: null
        }
    },
    beforeCreate() {

    },
    mounted() {

    },
    created() {    
        this.authState.setLayout(false)
        this.$root.$refs.MAccount = this;
        var settings = {
            canTimeout: true,
            defaultTitle: true,
            duration: 5000,
            withBackdrop: true,
            position: "top-right",
        };
        this.$vToastify.setSettings(settings);
    },
    methods: {

        async getnik() {
            try {

                //const { data, error } = await supabase.auth.getUser();
                //                   // console.log(data)
                //   if(error){
                //     console.log(error)
                //   }
            } catch (error) {
                console.log(error)
            }

        },
        async submitHandler() {
            try {
                const { data, error } = await supabaseadmin.createUser({
                    email: this.formValues.email,
                    password: this.formValues.password,

                });

                if (error) {
                    // Handle the error here
                    this.$vToastify.error(
                        "Registration is failed! " + error.message,
                        "Error")
                    return;
                } else {
                    // Handle the successful signup here
                    console.log('Signup success:', data);

                    const { data: res, error: insertError } = await supabase
                        .from('UserDetail')
                        .upsert([{
                            userid: data.user.id,
                            nik: this.formValues.nik,
                            nick_name: this.formValues.nickname,
                            nama_lengkap: this.formValues.fullname,
                            Jenis_kelamin: this.formValues.gender,
                            department: this.formValues.department,
                            Jabatan: this.formValues.position
                        }])
                        .select()
                    if (insertError) {
                        //If data insertion fails, cancel the user registration
                        //  Delete the user from the authentication system
                        await supabase.auth.signOut(); // Sign the user out
                        const { data: deldata, error: deldataerror } = await supabaseadmin.deleteUser(data.user.id)
                        console.log(deldata)
                        console.log(deldataerror)
                        console.log('deleted') // Delete the user from authentication



                        this.$vToastify.error(
                            "Registration is failed!",
                            "Error")


                    }
                    else {
                        this.$vToastify.success(
                            "Registration successfull, Please confirm at your Email! \n You will be redirected..",
                            "Success")

                        console.log(res)
                        console.log(insertError)
                        setTimeout(() => {
                            this.$router.push('/login');
                        }, 3000);
                    }
                }
            } catch (error) {
                // Handle any unexpected errors here
                console.error(error);
            }
        },
    }
}
</script>

<style lang="scss" scoped></style>