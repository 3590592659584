const { createClient } = require("@supabase/supabase-js");

const supabaseurl = 'https://mbwmrsijbrloymppycyh.supabase.co';
const supabasekey = " ";
const servicekey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1id21yc2lqYnJsb3ltcHB5Y3loIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY5MzUzNDU5NSwiZXhwIjoyMDA5MTEwNTk1fQ.3IVEEEYOhHGA2nKc7sgeTnaquFbD4-ZPbbppYkS8zsA"
const supabase = createClient(supabaseurl, servicekey, {
  autoRefreshToken: true,
  persistSession: true,
  detectSessionInUrl: false,
  realtime: true,
});
const supabaseadmin = supabase.auth.admin
export { supabase, supabaseurl, supabasekey, supabaseadmin };