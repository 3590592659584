<template>

<div v-if="authState.isLayout">
  <Layout>

</Layout>
</div>
<div v-else>
  <router-view></router-view>
</div>


</template>

<script>
//import HelloWorld from './components/HelloWorld';
import Layout from './components/Layout.vue'
import {useAuth} from './state/auth'
export default {
  name: 'App',

  components: {
    Layout
  //  HelloWorld,
  },

  data: () => ({
    authState: useAuth()
    //
  }),
};
</script>
