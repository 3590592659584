<template>
    <div>
		<div class="columns is-multiline">
			<div class="column is-3">

				<input type="date" v-model="date" class="form-control">
			</div>
			<div class="column is-3">
				<select required class="form-control" v-model="shift">
					<option value="">Select Shift</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
				</select>
			</div>
			<div class="column is-3">

				<button class="btn btn-success" @click="generate">OK</button>
			</div>
		</div>

    </div>
</template>

<script>
    export default {
       data() {
        return {
            shift: 0,
			date: null,
        }
       },
    }
</script>

<style lang="scss" scoped>

</style>