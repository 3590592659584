//import HelloWorld from '../components/HelloWorld.vue'


// import HelloWorld from '../components/HelloWorld'
// import HellX from '../components/HellX'
import Dashboard from '../components/Dashboard/Dashboard.vue'
import Expjs from '../components/Dashboard/Expjs.vue'
import Login from '../components/Auth/Login.vue'
import Register from '../components/Auth/Register.vue'
import Mix from '../components/KeyIn/Mix.vue'
import Holding from '../components/KeyIn/Holding'
import Sterilizer from '../components/KeyIn/Sterilizer.vue'
import ShiftlyReport from '../components/Report/ShiftlyReport.vue'
import ShiftlyReportHeader from '../components/Report/ShiftlyHeader.vue'
import ForgotPassword from '../components/Auth/ForgotPassword.vue'
import RecoverySent from '../components/Auth/RecoverySent.vue'
import ErrorAuth from  '../components/Auth/ErrorAuth.vue'
import ConfirmRecPassword from '../components/Auth/ConfirmPassword.vue'
import Logout from '../components/Auth/Logout.vue'
// import Layout from '../components/Layout'
// /* ----------------------------- end of testing ----------------------------- */

export default [{
        path: '/',
        component: Dashboard
    },
{
        path: '/ForgotPassword',
        component: ForgotPassword
    },{
        path: '/ex',
        component: Expjs
    },{
        path: '/login',
        component : Login
    },
    {
        path: '/register',
        component : Register
    } ,{
        path: '/Mix',
        component : Mix
    },{
        path: '/HoldingTank',
        component : Holding
    },{
        path: '/Sterilizer',
        component : Sterilizer
    },
    {
        path: '/ShiftlyReport',
        component : ShiftlyReport
    },
     {
        path: '/ShiftlyReportHeader',
        component : ShiftlyReportHeader
    },  {
        path: '/RecSent',
        component : RecoverySent
    },  {
        path: '/AuthError',
        component : ErrorAuth
    }, {
        path: '/ConfirmPassword',
        component : ConfirmRecPassword
    },
    {
        path: '/Logout',
        component : Logout
    },

]