<template>
    <div>
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <a href="#" class="d-inline-block auth-logo">
                                    <img src="assets/logo.png" alt="" height="50">
                                </a>
                            </div>
                            <p class="mt-3 fs-15 fw-medium">KeyIn System</p>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">

                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">Forgot Password?</h5>
                                    <p class="text-muted">Reset password</p>

                              
                                </div>

                                <div class="alert border-0 alert-warning text-center mb-2 mx-2" role="alert">
                                    Congratulation! <br>
                                    Confirm your new password & <strong>Make sure the password is different and have minimum 8 character!</strong>
                                </div>
                                <div class="p-2">
                                    <form>
                                        <div class="mb-4">
                                            <label class="form-label">Password</label>
                                            <input validation="required|min:8"
                                                             type="password" name="password"
                                                              class="form-control pe-5 password-input" />
                                        </div>
<div class="mb-4">
                                            <label class="form-label">Confirm Password</label>
                                            <input validation="required|min:8"
                                                             type="password" name="password"
                                                              class="form-control pe-5 password-input" v-model="password" />
                                        </div>
                                        <div class="text-center mt-4">
                                            <a class="btn btn-success w-100" @click="Reset">Change Password Now</a>
                                        </div>
                                    </form><!-- end form -->
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                        <div class="mt-4 text-center">
                            <p class="mb-0">Wait, I remember my password... <a href="/login"
                                    class="fw-semibold text-primary text-decoration-underline"> Click here </a> </p>
                        </div>

                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
    </div>
</template>

<script>
import { supabase } from '../../supabase/supabase';
    export default {
        data() {
            return {
                password: '',
            }
        },
        methods: {
            async Reset() {
            try {
                await supabase.auth.updateUser({
                    // email: this.email,
                    password: this.password
                });
                const { error } = await supabase.auth.signOut()
                this.$router.push('/login');
                console.log(error);
            } catch (error) {
                 this.$router.push('/AuthError');
            }


        }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>